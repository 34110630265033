import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import MergeEngineUtilities from "../../utilities/src/MergeEngineUtilities";
import { getQueryParams, insertParam } from "../../../components/src/utilities/utils";
import { IProduct, ICart } from '../../../components/src/types/types'
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  t: any
  // Customizable Area End
}

interface S {
  arrayHolder: any;
  token: string;
  // Customizable Area Start
  categoryName?: string,
  categoryId?: number,
  subCategoryId?: number,
  subCategoryName?: string,
  selectedMaterialIds: number[],
  selectedDiameterIds: number[],
  selectedSchIds: number[],
  isRequestProductDialogSubmitted: boolean,
  isRequestProductDialogLoading: boolean,
  products: IProduct[],
  cart: ICart,
  searchValue?: string,
  loading: 'INITIAL' | 'CATALOGUE' | 'NEW_PRODUCTS' | null
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CatalogueController extends BlockComponent<Props, S, SS> {
  getProductApiCallId: any;
  fetchLanguageTextsCallId: any = '';
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
 // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
   
    ];

    this.state = {
      arrayHolder: [],
      token: "",
      selectedMaterialIds: [],
      selectedDiameterIds: [],
      selectedSchIds: [],
      isRequestProductDialogSubmitted: false,
      isRequestProductDialogLoading: false,
      products: [],
      cart: { products: {}, totalPrice: 0, prices: {}, productDetails: {} },
      loading: 'INITIAL'
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    this.redirectToLandingPage = this.redirectToLandingPage.bind(this);
    this.changeFilterId = this.changeFilterId.bind(this);
    this.updateStateFromUrlParams = this.updateStateFromUrlParams.bind(this);
    this.onRequestProductFormSubmit = this.onRequestProductFormSubmit.bind(this);
    this.onRequestProductDialogClosed = this.onRequestProductDialogClosed.bind(this);
    this.handleCatalogueResponse = this.handleCatalogueResponse.bind(this);
    this.updateProductQuantityFromCart = this.updateProductQuantityFromCart.bind(this);
    this.initCart = this.initCart.bind(this);
    this.handleNavigation = this.handleNavigation.bind(this);
    this.handleValidationResponse = this.handleValidationResponse.bind(this);
    this.handleRequestProductResponse = this.handleRequestProductResponse.bind(this);
    this.fetchCatalogue = this.fetchCatalogue.bind(this);
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
 // Customizable Area Start
    if (MergeEngineUtilities.isLoggedIn()) {
      this.fetchCatalogue();
      
    } else {
      this.validationApiCallId = await MergeEngineUtilities.validateToken(this.props);
    }

    this.initCart();
    this.updateStateFromUrlParams();
    this.fetchLanguageTextsCallId = MergeEngineUtilities.fetchLanguageTexts();
     // Customizable Area End
  }
  
  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      this.handleValidationResponse(responseJson, apiRequestCallId);
      this.handleCatalogueResponse(responseJson, apiRequestCallId);
      this.handleRequestProductResponse(responseJson, apiRequestCallId);

      if (this.fetchLanguageTextsCallId === apiRequestCallId) {
        MergeEngineUtilities.handleLanguageResponse(responseJson);
      }

      this.parseApiCatchErrorResponse(errorReponse);
    } else if (message.id === getName(MessageEnum.NavigationPayLoadMessage)) {
      const data = message.getData(getName(MessageEnum.NavigationData));
      this.handleNavigation(data);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  validationApiCallId: any = '';
  getCatalogueApiCallId: any = '';
  requestProductApiCallId: any = '';
  searchCatalogueApiCallId: any = '';

  handleValidationResponse(responseJson: any, apiRequestCallId: string) {
    if (apiRequestCallId === this.validationApiCallId) {
      if (responseJson && Array.isArray(responseJson.messages) && responseJson.messages[0] && responseJson.messages[0].Token) {
        this.fetchCatalogue();
      } else {
        MergeEngineUtilities.logout(this.props);
      }
    }
  }

  handleCatalogueResponse(responseJson: any, apiRequestCallId: string) {
    if (
      (apiRequestCallId === this.getCatalogueApiCallId)
      || (apiRequestCallId === this.searchCatalogueApiCallId)
    ) {
      this.setState({ loading: null })

      if (!responseJson.errors) {
        const newProducts = responseJson.data.map((item: any) => ({
          ...item.attributes,
          id: parseInt(item.id)
        }));

        let { products } = this.state;
        products = [...products, ...newProducts];
    
        this.setState({ products })
      } else {
        this.parseApiErrorResponse(responseJson);
      }
    }
  }

  handleRequestProductResponse(responseJson: any, apiRequestCallId: string) {
    if (apiRequestCallId === this.requestProductApiCallId) {
      if (!responseJson.errors) {
        this.setState({
          isRequestProductDialogLoading: false,
          isRequestProductDialogSubmitted: true
        })
      } else {
        this.parseApiErrorResponse(responseJson);
      }
    }
  }

  handleNavigation(data: any) {
    if (data && data.searchValue) {
      insertParam('search_value', data.searchValue);
      this.setState({ searchValue: data.searchValue })
    } else if (data) {
      insertParam('cat_name', data.category_name);
      insertParam('cat_id', data.category_id);
      insertParam('sub_cat_name', data.subcategory_name);
      insertParam('sub_cat_id', data.subcategory_id);

      this.setState({
        categoryName: data.category_name,
        categoryId: data.category_id,
        subCategoryName: data.subcategory_name,
        subCategoryId: data.subcategory_id,
      })
    }
  }

  redirectToLandingPage(isSubCategoryClicked?: boolean) {
    let params: any = undefined;
    if (isSubCategoryClicked) {
      params = { category_id: this.state.categoryId }
    }

    MergeEngineUtilities.navigateToScreen('LandingPage', this.props, params)
  }

  changeFilterId(id: number, filterType: 'MATERIAL' | 'DIAMETER' | 'SCH',) {
    const state: any = {
      selectedMaterialIds: [...this.state.selectedMaterialIds],
      selectedDiameterIds: [...this.state.selectedDiameterIds],
      selectedSchIds: [...this.state.selectedSchIds]
    }

    switch (filterType) {
      case 'DIAMETER':

        if (state.selectedDiameterIds.includes(id)) {
          state.selectedDiameterIds = state.selectedDiameterIds.filter((val: number) => val !== id)
        } else {
          state.selectedDiameterIds.push(id);
        }

        insertParam(filterType, state.selectedDiameterIds);
        break;
      case 'MATERIAL':
        if (state.selectedMaterialIds.includes(id)) {
          state.selectedMaterialIds = state.selectedMaterialIds.filter((val: number) => val !== id)
        } else {
          state.selectedMaterialIds.push(id);
        }

        insertParam(filterType, state.selectedMaterialIds);
        break;
      case 'SCH':
        if (state.selectedSchIds.includes(id)) {
          state.selectedSchIds = state.selectedSchIds.filter((val: number) => val !== id)
        } else {
          state.selectedSchIds.push(id);
        }

        insertParam(filterType, state.selectedSchIds);
        break;
    }

    this.setState((prevState) => ({
      ...prevState,
      ...state
    }))
  }

  updateStateFromUrlParams() {
    const queryParams = getQueryParams();
    const state: any = {}
    const stateMatchers = {
      search_value: 'searchValue',
      cat_id: 'categoryId',
      cat_name: 'categoryName',
      sub_cat_name: 'subCategoryName',
      sub_cat_id: 'subCategoryId',
      MATERIAL: 'selectedMaterialIds',
      DIAMETER: 'selectedDiameterIds',
      SCH: 'selectedSchIds'
    }

    if (queryParams.length > 0) {
      queryParams.forEach((param) => {
        let value = param.value;
        const key = param.key as 'search_value' | 'cat_id' | 'cat_name' | 'sub_cat_name' | 'sub_cat_id' | 'MATERIAL' | 'DIAMETER' | 'SCH'
        const stateKey = stateMatchers[key]

        if (['cat_id', 'sub_cat_id'].includes(key)) {
          state[stateKey] = parseInt(value as string);
        }

        if (['search_value', 'cat_name', 'sub_cat_name'].includes(key)) {
          state[stateKey] = value as string;
        }

        if (['MATERIAL', 'DIAMETER', 'SCH'].includes(key)) {
          state[stateKey] = (value as string).split(',').map((i) => parseInt(i))
        }
      })
    }

    this.setState((prevState) => ({
      ...prevState,
      ...state
    }))
  }

  fetchCatalogue(pageNumber: number = 1) {
    setTimeout(() => { // Don't remove settimeout
      if (pageNumber % 1 !== 0) {
        return;
      }

      this.setState({ loading: pageNumber === 1 ? 'CATALOGUE' : 'NEW_PRODUCTS' }, () => {
        if (this.state.searchValue) {
          const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
          this.searchCatalogueApiCallId = requestMessage.messageId;
      
          requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.searchCatalogueApiEndpoint}?page=${pageNumber}`
          );
      
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postApiMethod
          );
      
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify({ ...configJSON.apiHeader, token: MergeEngineUtilities._token })
          );
    
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify({ query: this.state.searchValue })
          );
      
          runEngine.sendMessage(requestMessage.id, requestMessage);
        } else {
          const el = document.querySelector('.pantalone-table');
          if (el) { el.scrollTo(0, el.scrollHeight) }

          const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
          this.getCatalogueApiCallId = requestMessage.messageId;
      
          requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.catalogueApiEndPoint}?page=${pageNumber}&category_id=${this.state.categoryId}&sub_category_id=${this.state.subCategoryId}`
          );
      
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethod
          );
      
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify({ ...configJSON.apiHeader, token: MergeEngineUtilities._token })
          );
      
          runEngine.sendMessage(requestMessage.id, requestMessage);
        }
      })
    }, 0)
  }

  onRequestProductFormSubmit(productName: string) {
    this.setState({
      isRequestProductDialogLoading: true
    }, () => {
      const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
      this.requestProductApiCallId = requestMessage.messageId;
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.requestProductApiEndPoint}`
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.postApiMethod
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify({ ...configJSON.apiHeader, token: MergeEngineUtilities._token })
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify({ catalogue_request: { product_name: productName, account_id: MergeEngineUtilities._customer_id } })
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
    });
  }

  onRequestProductDialogClosed() {
    this.setState({
      isRequestProductDialogLoading: false,
      isRequestProductDialogSubmitted: false
    })
  }

  async initCart() {
    const cart = await MergeEngineUtilities.initCart();
    this.setState({ cart: cart });
  }

  async updateProductQuantityFromCart(productId: IProduct['product_id'], qty: number) {
    const cart = await MergeEngineUtilities.updateCart(productId, qty, 'UPDATE');
    this.setState({ cart: cart })
  }
  // Customizable Area End
}
